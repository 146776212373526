import { forwardRef } from 'react';

import { TVComponentWithElementProps } from '../../../../style-system/models';
import { useFieldContext } from '../context';

import { helpTextStyles } from './help-text.styles';

type HelpTextProps = TVComponentWithElementProps<'div', typeof helpTextStyles>;

const HelpText = forwardRef<HTMLDivElement, HelpTextProps>(function ErrorMessage({ size, className, ...props }, ref) {
    const field = useFieldContext();
    const styles = helpTextStyles({ size: size ?? field.size, className });

    /** If there is an error message, hide help text */
    if (field?.invalid && field?.hasFeedbackText) return null;

    return <div {...field?.getHelpTextProps(props, ref)} className={styles} />;
});

export type { HelpTextProps };
export { HelpText };
