import { tVariants } from '../../../../style-system';

const labelStyles = tVariants({
    slots: {
        wrapper: 'py-1 gap-2 inline-flex items-center justify-between group-[&.split]/field:pt-2',
        label: 'font-semibold group-data-[disabled]:cursor-not-allowed',
    },
    variants: {
        size: {
            sm: {
                label: 'text-xs',
            },
            md: {
                label: 'text-sm',
            },
            lg: {
                label: 'text-md',
            },
        },
    },
});

export { labelStyles };
