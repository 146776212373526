import { forwardRef } from 'react';
import { useController } from 'react-hook-form';

import { useMergeRefs } from '../../../hooks';
import { CombineRefWithProps } from '../../../models';
import { callAll } from '../../../utils';
import { Field } from '../field';
import type { FieldOrientationOptions } from '../field/context/field-context.model';
import { Select, SelectProps } from '../select';

import type { FieldValues, FormFieldWithControllerProps } from './form.model';

type FormSelectProps<T extends FieldValues> = FormFieldWithControllerProps<T, SelectProps> &
    FieldOrientationOptions<true>;

export declare function FormSelectComponent<T extends FieldValues>(
    props: CombineRefWithProps<HTMLButtonElement, FormSelectProps<T>>,
): JSX.Element;

const FormSelect = forwardRef<HTMLButtonElement, FormSelectProps<any>>(function FormSelect(
    {
        name,
        control,
        options,
        errorMessage,
        hideErrorMessage = false,
        helpText,
        onValueChange,
        disabled,
        readOnly,
        required,
        defaultValue,
        label,
        id,
        size,
        orientation,
        ...rest
    },
    ref,
) {
    const {
        field,
        fieldState: { error },
    } = useController({
        name,
        control,
        rules: { required },
        disabled,
        defaultValue,
    });

    const internalRef = useMergeRefs(field.ref, ref);

    return (
        <Field
            size={size}
            invalid={!!error?.message}
            required={required}
            readOnly={readOnly}
            id={id}
            orientation={orientation}
        >
            <Field.Orientation>
                {label ? <Field.Label>{label}</Field.Label> : null}
                <Select
                    {...rest}
                    ref={internalRef}
                    name={field.name}
                    options={options}
                    value={field.value ?? ''}
                    defaultValue={defaultValue}
                    onValueChange={callAll(field.onChange, onValueChange)}
                />
            </Field.Orientation>
            {hideErrorMessage ? null : <Field.ErrorMessage>{errorMessage ?? error?.message}</Field.ErrorMessage>}
            {helpText ? <Field.HelpText>{helpText}</Field.HelpText> : null}
        </Field>
    );
}) as unknown as typeof FormSelectComponent;

export { FormSelect };
