import { tVariants } from '../../../../style-system';

const errorMessageStyles = tVariants({
    base: 'w-full text-start text-red-400 mt-1',
    variants: {
        size: {
            sm: 'text-xxs',
            md: 'text-xs',
            lg: 'text-sm',
        },
    },
});

export { errorMessageStyles };
