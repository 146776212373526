import { tVariants } from '../../../style-system';

const fieldStyles = tVariants({
    base: 'flex flex-col text group',
    variants: {
        fullWidth: { true: 'w-full' },
        size: {
            sm: 'text-xs',
            md: 'text-sm',
            lg: 'text-md',
        },
    },
});

export { fieldStyles };
