import { tVariants } from '../../../style-system';
import { factoryStyleProps } from '../../../style-system/factory/style-props';

const textareaStyles = tVariants({
    base: 'relative rounded-md inline-flex items-center justify-center focus-within:text-content text-content-muted focus-within:ring-primary-focus focus-within:ring-1',
    slots: {
        textarea:
            'w-full text-inherit text-current bg-transparent placeholder:text-content-deselected border-0 border-transparent outline-none focus:text-content focus:border-transparent focus:ring-0 focus:outline-none [&::-webkit-search-cancel-button]:appearance-none',
    },
    defaultVariants: {
        variant: 'outline-transparent',
    },
    variants: {
        variant: {
            outline: 'border border-surface-divider data-error:border-content-danger',
            'outline-transparent': 'border border-surface-divider data-error:border-content-danger bg-transparent',
            transparent: 'bg-transparent',
            ghost: 'bg-surface',
        },
        disabled: { true: 'opacity-40 cursor-not-allowed' },
        noOutline: { true: 'focus-within:ring-transparent focus-within:ring-0' },
        size: {
            sm: {
                base: 'text-xs [--icon-size:1.25rem]',
                textarea: 'px-2 py-1',
            },
            md: {
                base: 'text-sm [--icon-size:1.5rem]',
                textarea: 'px-2 py-2',
            },
            lg: {
                base: 'text-md [--icon-size:1.75rem]',
                textarea: 'px-2 py-3',
            },
        },
        resize: {
            false: {
                textarea: 'resize-none',
            },
        },
        w: factoryStyleProps.size.w,
    },
});

export { textareaStyles };
