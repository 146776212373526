import { tVariants } from '../../../../style-system';

const helpTextStyles = tVariants({
    base: 'w-full text-start text-content-muted mt-1',
    variants: {
        size: {
            sm: 'text-xxs',
            md: 'text-xs',
            lg: 'text-sm',
        },
    },
});

export { helpTextStyles };
