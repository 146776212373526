import { forwardRef } from 'react';

import { Button, ButtonProps } from '../../button';

type FormSubmitProps = ButtonProps;

const FormSubmit = forwardRef<HTMLButtonElement, FormSubmitProps>(function FormSubmit(props, ref) {
    return <Button type="submit" intent={props.intent ?? 'primary'} {...props} ref={ref} />;
});

export type { FormSubmitProps };
export { FormSubmit };
