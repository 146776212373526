import { tVariants } from '../../../style-system';

const radioGroupStyles = tVariants({
    base: 'flex flex-col',
    variants: {
        orientation: {
            horizontal: 'flex-row',
            vertical: 'flex-col',
        },
        size: {
            sm: 'gap-1',
            md: 'gap-2',
            lg: 'gap-3',
        },
    },
    compoundVariants: [
        {
            orientation: 'horizontal',
            size: 'sm',
            className: 'gap-3',
        },
        {
            orientation: 'horizontal',
            size: 'md',
            className: 'gap-4',
        },
        {
            orientation: 'horizontal',
            size: 'lg',
            className: 'gap-6',
        },
    ],
    defaultVariants: {
        size: 'md',
        orientation: 'vertical',
    },
});

export { radioGroupStyles };
