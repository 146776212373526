import { forwardRef, useMemo } from 'react';

import { mapClassNamesToSlots, PropsOf, TVStyleProps } from '../../../style-system';
import { callAll, callWith, safeAttr } from '../../../utils';
import { Text } from '../../text';

import { radioStyles } from './radio.styles';

type RadioProps = PropsOf<'input', 'value' | 'name' | 'size'> &
    TVStyleProps<typeof radioStyles> & {
        name: string;
        checked?: boolean;
        onValueChange?: (value: string) => void;
        value: string;
    };

const RADIO_LABEL_SIZE_MAP = {
    sm: 'xs',
    md: 'sm',
    lg: 'md',
} as const;

const Radio = forwardRef<HTMLInputElement, RadioProps>(function Radio(
    {
        value,
        onChange,
        onKeyDown,
        size = 'md',
        checked,
        onValueChange,
        name,
        children,
        classNames,
        disabled,
        readOnly,
        'aria-label': ariaLabel,
        ...rest
    },
    ref,
) {
    const { base, control, controlWrapper } = useMemo(
        () =>
            mapClassNamesToSlots(radioStyles, {
                checked,
                size,
                disabled,
                readOnly,
                classNames,
            }),
        [checked, size, readOnly, disabled, classNames],
    );

    return (
        <label className={base} {...safeAttr(checked, 'checked')}>
            <span className="sr-only">
                <input
                    type="radio"
                    name={name}
                    value={value}
                    checked={checked}
                    onChange={callAll(callWith(onValueChange, value), onChange)}
                    ref={ref}
                    disabled={disabled}
                    readOnly={readOnly}
                    {...rest}
                />
            </span>
            <span className={controlWrapper}>
                <button
                    className={control}
                    type="button"
                    role="radio"
                    aria-checked={!!checked}
                    aria-label={ariaLabel ?? value}
                />
            </span>
            <Text as="span" size={RADIO_LABEL_SIZE_MAP[size]} color="muted">
                {children}
            </Text>
        </label>
    );
});

export type { RadioProps };
export { Radio };
