import React, { forwardRef, useCallback, useEffect, useState } from 'react';

import { useDebounceCallback } from '@blockworks/platform/hooks';

import { callAllHandlers } from '../../../utils';
import { Text, type TextProps } from '../../text';
import { type SimpleFieldOptions, useFieldProps } from '../field/context';

import { editableTextStyles } from './editable-text.styles';
import { useEditableTextCursorPos } from './editable-text.utils';

type EditableTextProps = Omit<TextProps<'span'>, 'onChange' | 'onBlur' | 'value' | 'defaultValue'> & {
    name?: string;
    value?: string;
    defaultValue?: string;
    onChange?: (value: string) => void;
    onBlur?: (value?: React.FocusEvent<HTMLSpanElement> | string) => void;
    debounce?: number;
} & SimpleFieldOptions;

const EditableText = forwardRef<HTMLSpanElement, EditableTextProps>(function EditableText(
    {
        value,
        defaultValue,
        onChange,
        id,
        onBlur,
        readOnly,
        disabled,
        required,
        invalid,
        size,
        className,
        debounce = 0,
        ...rest
    },
    passedRef,
) {
    const [content, setContent] = useState(defaultValue ?? '');
    const { saveCursorPosition, resetCursorPosition, mergeRefs, ref } = useEditableTextCursorPos(passedRef);

    useEffect(() => {
        setContent(value ?? '');
    }, [value]);

    useEffect(() => {
        const editableText = ref.current;
        if (editableText && editableText.innerText !== content) {
            /** ensure that the innerText is an empty string if the value is falsy */
            editableText.innerText = !content ? '' : content;
        }
        return () => {
            if (editableText) {
                editableText.innerText = '';
            }
        };
    }, [content, ref]);

    const debouncedOnChange = useDebounceCallback(onChange, debounce);
    const handleInput = useCallback(
        (e: React.FormEvent<HTMLSpanElement>) => {
            const newContent = e.currentTarget.innerText ?? '';
            saveCursorPosition();
            setContent(newContent);
            debouncedOnChange(newContent);
        },
        [debouncedOnChange, saveCursorPosition],
    );

    const handleKeyDown = (e: React.KeyboardEvent<HTMLSpanElement>) => {
        if (e.key === 'Enter') {
            (e.target as HTMLElement).blur();
        }
    };

    const { fieldProps, rootProps } = useFieldProps<HTMLSpanElement>({
        readOnly,
        disabled,
        required,
        invalid,
        id,
        onBlur: callAllHandlers(resetCursorPosition, onBlur),
        ...rest,
    });

    return (
        <span {...rootProps}>
            <Text
                as="span"
                size={size}
                px={1}
                py={1}
                contentEditable={fieldProps.disabled ? 'false' : 'true'}
                onInput={handleInput}
                onKeyDown={handleKeyDown}
                ref={mergeRefs}
                className={editableTextStyles({ className })}
                {...fieldProps}
            />
        </span>
    );
});

export type { EditableTextProps };
export { EditableText };
