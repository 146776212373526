import { AnyObj } from '@blockworks/platform/typescript';

type PredicateFn<T> = (key: T) => boolean;

type SplitPropsFn = {
    <T extends AnyObj, K extends keyof T>(props: T, keys: K[]): [Pick<T, K>, Omit<T, K>];
    <T extends AnyObj, K extends PredicateFn<keyof T>>(props: T, keys: K): [AnyObj, AnyObj];
};

/**
 * A reusable helper to separate groups of props
 */
const splitProps: SplitPropsFn = (props: any, keys: any[]) => {
    const descriptors = Object.getOwnPropertyDescriptors(props);
    const dKeys = Object.keys(descriptors);
    const split = (k: string[]) => {
        const clone = {} as AnyObj;
        for (let i = 0; i < k.length; i++) {
            const key = k[i];
            if (key && descriptors[key]) {
                Object.defineProperty(clone, key, descriptors[key] as any);
                delete descriptors[key];
            }
        }
        return clone;
    };
    const fn = (key: any) => split(Array.isArray(key) ? key : dKeys.filter(key));
    return [keys].map(fn).concat(split(dKeys)) as any;
};

/** A factory for creating a `splitProps` helper, which helps to separate groups of props */
const makeSplitProps = <T>(keys: (keyof T)[]) => {
    return <Props extends Partial<T>>(props: Props) => splitProps(props, keys) as [T, Omit<Props, keyof T>];
};

export type { SplitPropsFn };
export { makeSplitProps };
