import { tVariants } from '../../../../style-system';

const fieldOrientationStyles = tVariants({
    base: 'flex flex-col gap-3 group/field',
    defaultVariants: {
        direction: 'horizontal',
    },
    variants: {
        direction: {
            horizontal: 'flex-row',
            vertical: 'flex-col',
        },
        reverse: {
            true: 'justify-end',
        },
        split: { true: 'split' },
    },
    compoundVariants: [
        {
            direction: 'horizontal',
            reverse: true,
            className: 'flex-row-reverse justify-end',
        },
        {
            direction: 'vertical',
            reverse: true,
            className: 'flex-col-reverse items-end',
        },
        {
            direction: 'vertical',
            className: 'gap-0.5',
        },
        { direction: 'horizontal', split: true, className: 'grid grid-cols-[1fr_1fr] items-start' },
    ],
});

export { fieldOrientationStyles };
