import { forwardRef } from 'react';

import { TVComponentWithElementProps } from '../../../../style-system/models';
import { useFieldContext } from '../context';

import { errorMessageStyles } from './error-message.styles';

type ErrorMessageProps = TVComponentWithElementProps<'div', typeof errorMessageStyles> & {
    spacer?: React.ReactNode;
};

const ErrorMessage = forwardRef<HTMLDivElement, ErrorMessageProps>(function ErrorMessage(
    { size, spacer = null, className, ...props },
    ref,
) {
    const field = useFieldContext();
    const styles = errorMessageStyles({ size: size ?? field.size, className });

    if (!field?.invalid) return spacer;

    return <div {...field?.getErrorMessageProps(props, ref)} className={styles} />;
});

export type { ErrorMessageProps };
export { ErrorMessage };
