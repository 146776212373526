import { tVariants } from '../../../style-system';

const checkboxStyles = tVariants({
    base: 'relative rounded-md inline-flex items-center justify-center',
    slots: {
        input: 'appearance-none w-4 h-4 border-surface-selected rounded text-primary-accent focus:outline-primary-ring',
    },
    variants: {
        disabled: { true: 'opacity-40 cursor-not-allowed' },
        size: {
            sm: {
                base: 'text-xs',
            },
            md: {
                base: 'text-sm ',
            },
            lg: {
                base: 'text-md ',
            },
        },
    },
});

export { checkboxStyles };
