import { forwardRef } from 'react';
import * as RadixSwitch from '@radix-ui/react-switch';

import { TVStyleProps } from '../../../style-system';
import { SimpleFieldOptions, useFieldProps } from '../field/context';

import { switchStyles } from './switch.styles';

type SwitchProps = RadixSwitch.SwitchProps & {
    /**
     * The aria-label for the switch.
     * @todo make this required */
    label?: string;
    id?: string;
    labelPosition?: 'left' | 'right';
    size?: TVStyleProps<typeof switchStyles>['size'];
} & SimpleFieldOptions;

const Switch = forwardRef<HTMLButtonElement, SwitchProps>(function Switch(
    { label = 'Switch', readOnly, disabled, required, onCheckedChange, invalid, ...rest },
    ref,
) {
    const { size, fieldProps, rootProps } = useFieldProps({
        ...rest,
        readOnly,
        disabled,
        required,
        invalid,
    });
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { base, container, thumb } = switchStyles({ size });

    return (
        <div className={container()} {...rootProps}>
            <RadixSwitch.Root
                className={base()}
                aria-label={label}
                {...fieldProps}
                disabled={fieldProps?.disabled || fieldProps?.readOnly}
                onCheckedChange={onCheckedChange}
                ref={ref}
            >
                <RadixSwitch.Thumb className={thumb()} />
            </RadixSwitch.Root>
        </div>
    );
});

export type { SwitchProps };
export { Switch };
