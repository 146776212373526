import { useEffect, useState } from 'react';
import { Control, UseFormGetValues, useWatch } from 'react-hook-form';

const useFieldsAreChanged = (
    initialValues: Record<string, any> | undefined,
    { control, getValues }: { control: Control<any>; getValues: UseFormGetValues<any> },
) => {
    const [changed, setChanged] = useState(false);
    const watchedValues = useWatch({ control });

    useEffect(() => {
        if (!initialValues) return;

        const currentValues = getValues();
        const someFieldChanged = Object.keys(initialValues).some(key => initialValues[key] !== currentValues[key]);
        setChanged(someFieldChanged);
    }, [watchedValues, initialValues, getValues]);

    return changed;
};

export { useFieldsAreChanged };
