import React, { PropsWithChildren } from 'react';
import * as RadixSelect from '@radix-ui/react-select';

import { TVStyleProps } from '../../../style-system';

import { selectItemStyles } from './select.styles';

type SelectItemProps = PropsWithChildren & {
    value: string;
    icon: React.ReactNode; // TODO: enforce icon only prop
    disabled?: boolean;
} & TVStyleProps<typeof selectItemStyles>;

const SelectItem = React.forwardRef<HTMLDivElement, SelectItemProps>(function SelectItem(
    { children, icon, value, disabled, size, ...props },
    ref,
) {
    return (
        <RadixSelect.Item
            className={selectItemStyles({ size })}
            {...props}
            disabled={disabled}
            value={String(value)}
            ref={ref}
        >
            {icon || null}
            <RadixSelect.ItemText>{children}</RadixSelect.ItemText>
        </RadixSelect.Item>
    );
});

export type { SelectItemProps };
export { SelectItem };
