import { createProps, makeSplitProps } from '../../../../utils';

type FieldOrientationDirection = 'horizontal' | 'vertical';

type FieldOrientationOptions<
    DisableControlDirection extends boolean = false,
    DisableReverse extends boolean = false,
    DisableDirection extends boolean = false,
> = {
    /** Orientation configuration for the field */
    orientation?: {
        split?: boolean;
        /**
         * Changes the orientation direction of the label and input control.
         **/
        direction?: DisableDirection extends true ? never : FieldOrientationDirection;
        /**
         * Reverses the order of the control and label.
         **/
        reverse?: DisableReverse extends true ? never : boolean;
        /**
         * Controls the orientation direction of a given control group, like a radio or checkbox group..
         **/
        controlDirection?: DisableControlDirection extends true ? never : FieldOrientationDirection;
    };
};

type FieldOptions<OrientationAsString extends boolean = false> = {
    /**
     * Indicates whether the element's orientation is horizontal, vertical, or unknown/ambiguous.
     * @default undefined
     */
    orientation?: OrientationAsString extends true ? FieldOrientationDirection : FieldOrientationOptions['orientation'];
    /**
     * If `true`, the form control will be required. This has 2 side effects:
     * - The `FormLabel` will show a required indicator
     * - The form element (e.g, Input) will have `aria-required` set to `true`
     *
     * @default false
     */
    required?: boolean;
    /**
     * If `true`, the form control will be disabled. This has 2 side effects:
     * - The `FormLabel` will have `data-disabled` attribute
     * - The form element (e.g, Input) will be disabled
     *
     * @default false
     */
    disabled?: boolean;
    /**
     * If `true`, the form control will be invalid. This has 2 side effects:
     * - The `FormLabel` and `FormErrorIcon` will have `data-invalid` set to `true`
     * - The form element (e.g, Input) will have `aria-invalid` set to `true`
     *
     * @default false
     */
    invalid?: boolean;
    /**
     * If `true`, the form control will be readonly
     *
     * @default false
     */
    readOnly?: boolean;
};

type SimpleFieldOptions = Omit<FieldOptions, 'orientation'>;

type UseFieldProps<T extends HTMLElement = HTMLElement> = FieldOptions &
    FieldStyles & {
        id?: string;
        onFocus?: React.FocusEventHandler<T>;
        onBlur?: React.FocusEventHandler<T>;
        disabled?: boolean;
        readOnly?: boolean;
        required?: boolean;
        'aria-describedby'?: string;
    };

type FieldStyles = {
    size?: 'sm' | 'md' | 'lg';
};

type FieldContext = FieldOptions &
    FieldStyles & {
        /**
         * The custom `id` to use for the form control. This is passed directly to the form element (e.g. Input).
         * - The form element (e.g. Input) gets the `id`
         * - The form label id: `form-label-${id}`
         * - The form error text id: `form-error-text-${id}`
         * - The form helper text id: `form-helper-text-${id}`
         */
        id?: string;
    };

const fieldProps = createProps<UseFieldProps>()([
    'orientation',
    'aria-describedby',
    'disabled',
    'id',
    'invalid',
    'readOnly',
    'required',
    'onBlur',
    'onFocus',
    'size',
]);

const splitFieldProps = makeSplitProps<UseFieldProps>(fieldProps);

export type {
    FieldContext,
    FieldOptions,
    FieldOrientationDirection,
    FieldOrientationOptions,
    SimpleFieldOptions,
    UseFieldProps,
};
export { splitFieldProps };
