import { tVariants } from '../../../style-system';

const radioStyles = tVariants({
    base: ['control-group', 'relative', 'max-w-fit', 'inline-flex justify-start items-center gap-2', 'cursor-pointer'],
    slots: {
        controlWrapper: [
            'relative',
            'overflow-hidden',
            'inline-flex items-center justify-center flex-shrink-0',
            'border-[1px] border-solid border-surface-interact',
            'rounded-full',
            'control-group-hover:bg-surface-deselected control-group-hover:border-surface-accent',
        ],
        control: ['z-10', 'appearance-none', 'opacity-0', 'scale-0', 'origin-center', 'rounded-full', 'bg-surface'],
    },
    variants: {
        disabled: {
            true: {
                base: 'opacity-50 cursor-not-allowed focus-within:ring-0',
                controlWrapper: 'control-group-hover:border-surface-interact opacity-50 pointer-events-none',
                control: 'pointer-events-none',
            },
        },
        readOnly: {
            true: {
                base: 'opacity-50 cursor-not-allowed focus-within:ring-0',
                controlWrapper: 'control-group-hover:border-surface-interact opacity-50 pointer-events-none',
                control: 'pointer-events-none',
            },
        },
        checked: {
            true: {
                base: 'text-content-selected',
                controlWrapper:
                    'control-group-hover:border-transparent border-transparent ring-offset-0 ring-2 ring-primary-ring control-group-hover:bg-transparent',
                control: 'border-transparent opacity-100 bg-primary-accent',
            },
        },
        size: {
            sm: {
                control: 'w-3 h-3 scale-[75%]',
            },
            md: {
                control: 'w-[0.775rem] h-[0.775rem] scale-[75%]',
            },
            lg: {
                control: 'w-[0.9rem] h-[0.9rem] scale-[65%]',
            },
        },
    },
    defaultVariants: {
        size: 'md',
    },
});

export { radioStyles };
