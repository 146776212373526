import { tVariants } from '../../../style-system';

const switchStyles = tVariants({
    base: [
        'rounded-full p-0.5 border-surface-interact bg-surface-selected active:bg-surface-interact data-checked:bg-primary-accent data-checked:border-content-interact disabled:border-surface-interact',
        'disabled:group-data-error:bg-danger-surface group-data-error:bg-danger-alt',
        'data-checked:disabled:bg-primary-interact',
        'disabled:cursor-not-allowed',
    ],
    slots: {
        thumb: 'block w-[--thumb-size] h-[--thumb-size] rounded-full translate-x-0 transition-transform duration-150 bg-white data-checked:translate-x-full',
        container: 'flex items-center group',
    },
    defaultVariants: {
        size: 'md',
    },
    variants: {
        size: {
            sm: {
                base: 'h-3 w-5 [--thumb-size:8px]',
            },
            md: {
                base: 'h-5 w-9 [--thumb-size:16px]',
            },
            lg: {
                base: 'h-7 w-12 [--thumb-size:20px]',
            },
        },
    },
});

export { switchStyles };
